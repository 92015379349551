import React, { Component } from 'react'
import GuestRouter from './config/routing/GuestRouter'
import CustomerRouter from 'config/routing/CustomerRouter'
import BrokerRouter from 'config/routing/BrokerRouter'
//import ChangePwRouter from 'config/routing/ChangePwRouter'
//import AdminRouter from 'config/routing/AdminRouter'
import { NotificationContainer } from 'react-notifications'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Loader from './components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
//import clientApi from 'config/api/ClientAPI'
import { DetailsProvider } from 'config/context/DetailsContext'
import { onError } from './config/lib/errorLib'
import ErrorBoundary from './components/general/ErrorBoundary'

const isLocal = process.env.NODE_ENV === 'development'

// Turn off logs in production
if (!isLocal) {
    console.log = function() {}
}

//const companyId = 'bq'
//const companyId = 'nph'
const companyId = 'al'

class App extends Component {
    constructor(props) {
        super(props)
        console.log(this.props)
        this.state = {
            loading: true,
            accountType: null,
            phone: null,
            name: null,
            companyId: null
        }
        this.api = new BrokerAPI()
    }

    async componentDidMount() {
        // if (companyId === 'al') {
        //     const favicon = document.getElementById('favicon')
        //     favicon.href = pcFavicon
        // }
        // if (companyId === 'qf') {
        //     const favicon = document.getElementById('favicon')
        //     favicon.href = qfFavicon
        // }
        await this.checkCurrentUser()
    }

    async checkCurrentUser() {
        try {
            const info = await Auth.currentUserInfo()
            console.log('info: ', info)
            console.log(this.props.authState)

            if (this.props.authState === 'signedIn' && Object.keys(info).length === 0) {
                this.logout()
            }

            // if (companyId === 'nph') {
            //     this.logout()
            // }

            let accountType = info ? info.attributes['custom:account_type'] : null
            let phone = info ? info.attributes['phone_number'] : null
            let firstName = (info && info.attributes['name']) ? info.attributes['name'].split(' ')[0] : null
            let lastName = (info && info.attributes['name']) ? info.attributes['name'].split(' ')[1] : null
            let companyDetails = {
                companyId: companyId
            }

            if (accountType === 'broker') {
                let details = await this.api.getDetails()
                console.log('broker details: ', details)

                if (!details) {
                    let permissions = info ? info.attributes['custom:permissions'] : 'broker'
                    let email = info.attributes['email'] ? info.attributes['email'] : ''
                    details = await this.api.addDetails(phone, permissions, firstName, lastName, email)
                }
            }

            this.setState({
                accountType: accountType,
                phone: phone,
                name: firstName + ' ' + lastName,
                companyDetails: companyDetails,
                loading: false
            })
        } catch(e) {
            onError(e)
        }
    }

    logout() {
        Auth.signOut().then((user) => {
            //window.location.replace('/')
            //this.props.history.push('/')
            console.log(user)
        }).catch(err => 
            onError(err)
        )
    }

    render() {
        return (
            <ErrorBoundary>
                <>
                    <NotificationContainer />
                    {!this.state.loading ? (
                        <>
                            {this.props.authState === 'signedIn' ? (
                                <DetailsProvider value={this.state.companyDetails}>
                                    {this.state.accountType === 'customer' &&
                                        <CustomerRouter phone={this.state.phone} name={this.state.name} />
                                    }
                                    {this.state.accountType === 'broker' &&
                                        <BrokerRouter phone={this.state.phone} name={this.state.name} />
                                    }
                                </DetailsProvider>
                            ) : (
                                <DetailsProvider value={this.state.companyDetails}>
                                    <GuestRouter />
                                </DetailsProvider>
                            )}
                        </>
                    ) : (
						<Loader 
							className='full-page-loader'
						/>
                    )}
                </>
            </ErrorBoundary>
        )
    }
}

export default withRouter(App)